const zoomSectionState = {
  seedURN:
    'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dmVyc2lvbjEvZnVsbG1vZGVsX25ld2JyaWNrX3YxLnJ2dA',
  objectSet: [
    { id: [], idType: 'lmv', isolated: [], hidden: [], explodeScale: 0 }
  ],
  viewport: {
    name: '',
    eye: [17.360107917381306, 79.10257087780141, 27.30801508416697],
    target: [7.274260797836787, 80.208662654236, 26.73379753830314],
    up: [-0.0561665305247858, 0.006159654889468187, 0.9984024136090872],
    worldUpVector: [0, 0, 1],
    pivotPoint: [
      -0.00000417695660814843,
      0.00000252085295926463,
      0.0000011920907070361864
    ],
    distanceToOrbit: 10.162552677683609,
    aspectRatio: 1.493660855784469,
    projection: 'orthographic',
    isOrthographic: true,
    orthographicHeight: 10.16255267768382
  },
  renderOptions: {
    environment: 'Boardwalk',
    ambientOcclusion: {
      enabled: true,
      radius: 13.123359580052492,
      intensity: 1
    },
    toneMap: { method: 1, exposure: -7, lightMultiplier: -1e-20 },
    appearance: {
      ghostHidden: true,
      ambientShadow: true,
      antiAliasing: true,
      progressiveDisplay: true,
      swapBlackAndWhite: false,
      displayLines: true,
      displayPoints: true
    }
  },
  cutplanes: null
};

const singleSourceState = {
  seedURN:
    'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dmVyc2lvbjEvZnVsbG1vZGVsX25ld2JyaWNrX3YxLnJ2dA',
  objectSet: [
    {
      id: [],
      idType: 'lmv',
      isolated: [
        20580,
        104916,
        104915,
        104918,
        11163,
        104963,
        104964,
        104965,
        11159,
        11189,
        104874,
        11185,
        104873,
        104872,
        104871,
        104966,
        20581,
        104917
      ],
      hidden: [],
      explodeScale: 0.4
    }
  ],
  viewport: {
    name: 'Explode',
    eye: [119.68439159143001, 93.65514605730671, -31.65121513071318],
    target: [59.121618051481214, 33.09237403811901, -92.21398988051017],
    up: [-0.40824830444361254, -0.40824829419229636, 0.8164965720736344],
    worldUpVector: [0, 0, 1],
    pivotPoint: [
      -0.00000417695660814843,
      0.00000252085295926463,
      0.0000011920907070361864
    ],
    distanceToOrbit: 104.8978006389738,
    aspectRatio: 1.2676321138211382,
    projection: 'orthographic',
    isOrthographic: true,
    orthographicHeight: 104.89780063897372
  },
  renderOptions: {
    environment: 'Boardwalk',
    ambientOcclusion: {
      enabled: true,
      radius: 13.123359580052492,
      intensity: 1
    },
    toneMap: { method: 1, exposure: -7, lightMultiplier: -1e-20 },
    appearance: {
      ghostHidden: true,
      ambientShadow: true,
      antiAliasing: true,
      progressiveDisplay: true,
      swapBlackAndWhite: false,
      displayLines: true,
      displayPoints: true
    }
  },
  cutplanes: null
};

export { singleSourceState, zoomSectionState };
