import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import ForgeViewer from './ForgeViewer';

const style = {
  contentContainer: {
    position: 'absolute',
    height: '99%',
    width: '99%',
    overflow: 'hidden',
    padding: 0,
    margin: 0
  },
  button: {
    backgroundColor: '#f91b1f',
    color: '#FFFFFF'
  },
  windowContainer: {
    zIndex: 1,
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },
  buttonContainer: {
    zIndex: 999999,
    top: 80,
    right: 20,
    position: 'absolute'
  }
};

class App extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.contentContainer}>
        <div className={classes.windowContainer}>
          <ForgeViewer urn="dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dmVyc2lvbjEvZnVsbG1vZGVsX25ld2JyaWNrX3YxLnJ2dA" />
        </div>
      </div>
    );
  }
}

export default withStyles(style)(App);
