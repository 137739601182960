const { AuthClientTwoLegged } = require('forge-apis');

const scopes = {
  // Required scopes for the server-side application
  internal: [
    'bucket:create',
    'bucket:read',
    'data:read',
    'data:create',
    'data:write'
  ],
  // Required scope for the client-side viewer
  public: ['viewables:read']
};

const config = {
  // Set environment variables or hard-code here
  credentials: {
    client_id: '57eOsvtGQbE8TliuJqxMZAj1hCjgHt6k',
    client_secret: 'd1dzIrbimfu8rSYW'
  }
};

/**
 * Initializes a Forge client for 2-legged authentication.
 * @param {string[]} scopes List of resource access scopes.
 * @returns {AuthClientTwoLegged} 2-legged authentication client.
 */
function getClient(scopes) {
  const { client_id, client_secret } = config.credentials;
  return new AuthClientTwoLegged(
    client_id,
    client_secret,
    scopes || scopes.internal
  );
}

let cache = {};
async function getToken(scopes) {
  const key = scopes.join('+');
  if (cache[key]) {
    return cache[key];
  }
  const client = getClient(scopes);
  let credentials = await client.authenticate();
  cache[key] = credentials;
  setTimeout(() => {
    delete cache[key];
  }, 86400000);
  return credentials;
}

/**
 * Retrieves a 2-legged authentication token for preconfigured public scopes.
 * @returns Token object: { "access_token": "...", "expires_at": "...", "expires_in": "...", "token_type": "..." }.
 */
async function getPublicToken() {
  return getToken(scopes.public);
}

/**
 * Retrieves a 2-legged authentication token for preconfigured internal scopes.
 * @returns Token object: { "access_token": "...", "expires_at": "...", "expires_in": "...", "token_type": "..." }.
 */
async function getInternalToken() {
  return getToken(scopes.internal);
}

export { getClient, getPublicToken, getInternalToken };
