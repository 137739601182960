import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import App from './App';
import store from './store';
import './explosion-extension';

const checkURI = window.location;
console.log('checkURI', checkURI);

const rootStore = new store();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={rootStore}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
