/* eslint array-callback-return: 0 */
import { observable, action, decorate } from 'mobx';

class Store {
  interactive = false;
  showButton = false;

  setInteractive = () => {
    this.interactive = true;
    this.showButton = false;
  };

  setShowButton = () => {
    this.showButton = true;
  };
}

decorate(Store, {
  interactive: observable,
  showButton: observable,
  setInteractive: action,
  setShowButton: action
});

export default Store;
