let Autodesk = window.Autodesk;

(function () {
  function MyExternalExtension(viewer, options) {
    Autodesk.Viewing.Extension.call(this, viewer, options);
  }

  MyExternalExtension.prototype = Object.create(
    Autodesk.Viewing.Extension.prototype
  );
  MyExternalExtension.prototype.constructor = MyExternalExtension;
  let proto = MyExternalExtension.prototype;

  proto.load = function () {
    console.log('External Extension loaded!');
    return true;
  };

  proto.unload = function () {
    console.log('External Extension unloaded!');
    return true;
  };

  proto.handleExplode = function (point, viewer, event, mc) {
    const model = viewer.model;
    const fragList = model.getFragmentList();

    const frameFrags = [
      1050,
      1051,
      1056,
      1052,
      1057,
      1053,
      1055,
      695,
      688,
      4852,
      1722,
      2059,
      4415,
      3731,
      4400,
      3156,
      4366,
      4399,
      1889,
      3669,
      4360,
      3667,
      4398,
      4414,
      1180,
      1723,
      5494,
      5300,
      1180,
      5501,
      5287,
      1723,
      1180,
      5500,
      5290,
      4010,
      4841,
      1041,
      1042,
      1042,
      1044,
      1045,
      1046,
      1048,
      689,
      1701,
      2026,
      1860,
      1043,
      715,
      3621,
      3313,
      4432,
      5384,
      4394,
      3619,
      4332,
      3620,
      4430,
      4333,
      3721,
      4393,
      4434,
      4040,
      5297,
      1688,
      5271,
      3974,
      4303,
      5337,
      5275,
      5514,
      1175,
      1176,
      1724,
      1181,
      1177,
      1182,
      1178,
      716,
      1627,
      4889,
      4853,
      4419,
      2060,
      3736,
      4404,
      3973,
      694,
      1629,
      5270,
      5515,
      3158,
      4353,
      4403,
      1848,
      3605,
      4491,
      3603,
      4402,
      4418,
      1848,
      5301,
      5492,
      1628,
      5516,
      5267,
      5515,
      5270,
      694
    ];
    const windowFrags = [
      50,
      6175,
      6340,
      6252,
      6066,
      6293,
      6248,
      6043,
      6121,
      6179,
      6351,
      6235,
      6045
    ];
    const foamFrags = [46, 34, 47];
    const wallFrags = [
      118,
      189,
      863,
      914,
      141,
      228,
      819,
      878,
      133,
      193,
      852,
      897
    ];

    const boxes = fragList.fragments.boxes;
    const numFrags = fragList.getCount();

    let scale = 0.1;

    for (let fragId = 0; fragId < numFrags; ++fragId) {
      if (
        [...windowFrags, ...wallFrags, ...frameFrags, ...foamFrags].indexOf(
          fragId
        ) > 0 &&
        mc.y !== 0
      ) {
        viewer.isolate([
          20580,
          104916,
          104915,
          104918,
          11163,
          104963,
          104964,
          104965,
          11159,
          11189,
          104874,
          11185,
          104873,
          104872,
          104871,
          104966,
          20581,
          104917
        ]);
        if (scale !== 0) {
          if (frameFrags.indexOf(fragId) > 0) {
            scale = 0.1;
          }
          if (foamFrags.indexOf(fragId) > 0) {
            scale = 0.25;
          }
          if (wallFrags.indexOf(fragId) > 0) {
            scale = 0.4;
          }
          if (windowFrags.indexOf(fragId) > 0) {
            scale = 0.55;
          }

          const box_offset = fragId * 6;
          let cy = boxes[box_offset + 1] + boxes[box_offset + 4];

          cy = scale * (cy - mc.y);
          point.y = cy;

          fragList.updateAnimTransform(fragId, null, null, point);
        }
      } else {
        fragList.updateAnimTransform(fragId);
      }
    }
    return true;
  };

  Autodesk.Viewing.theExtensionManager.registerExtension(
    'WWSingleSource.Extension',
    MyExternalExtension
  );
})();
