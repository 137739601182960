import React from 'react';
import Measure from 'react-measure';
import { decorate, observable, when, autorun } from 'mobx';
import { getPublicToken } from './oauth';
import * as THREE from 'three';
import { observer, inject } from 'mobx-react';
import withStyles from '@material-ui/core/styles/withStyles';

import './forgeViewerStyle.css';
import { zoomSectionState, singleSourceState } from './viewStates';

let Autodesk = window.Autodesk;

let inSingleSource = false;
let inZoomSection = false;

const style = {
  viewerDiv: {
    position: 'absolute',
    width: '99%',
    height: '99%',
    zIndex: 1
  }
};

class ForgeViewer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.doc = props.urn || '';
    this.viewerDiv = React.createRef();
    this.viewer = null;
    this.resizeHandling = null;

    this.viewerDivDispose = when(
      () => this.viewerDiv,
      () => {
        this.initializeViewer(props.urn);
      }
    );
    this.viewerDispose = autorun(() => this.viewer);
  }

  componentWillUnmount() {
    console.log('React Forge Viewer unmounting...');
    if (this.viewer) {
      this.viewer.tearDown();
      this.viewer.finish();
      this.viewer = null;
    }
    this.viewerDispose && this.viewerDispose();
    this.viewerDivDispose && this.viewerDivDispose();
    this.resizeHandling && clearTimeout(this.resizeHandling);
  }

  handleResize() {
    if (this.resizeHandling) clearTimeout(this.resizeHandling);

    this.resizeHandling = setTimeout(() => {
      if (this.viewer) this.viewer.resize();
    }, 100);
  }

  onToolbarCreated = event => {
    const setViewExplode = () => {
      if (!inZoomSection) {
        const storeView = this.viewer.getState();

        if (storeView) {
          localStorage.setItem('storeView', JSON.stringify(storeView));
        }
      }

      inSingleSource = true;
      inZoomSection = false;
      const zoomCheck = this.viewer.getCutPlanes();

      if (zoomCheck && zoomCheck.length) {
        const SectionTool = this.viewer.getExtension('Autodesk.Section');
        SectionTool.deactivate();
      }
      this.viewer.restoreState(singleSourceState);
    };
    const restoreViewExplode = () => {
      const restoreView = JSON.parse(localStorage.getItem('storeView'));
      if (restoreView && restoreView.viewport.name !== 'Explode') {
        this.viewer.restoreState(restoreView);
        inSingleSource = false;
      }
    };
    const setZoomSection = () => {
      if (!inSingleSource) {
        const storeView = this.viewer.getState();
        if (storeView) {
          localStorage.setItem('storeView', JSON.stringify(storeView));
        }
      }

      inZoomSection = true;
      inSingleSource = false;

      this.viewer.restoreState(zoomSectionState);
      const SectionTool = this.viewer.getExtension('Autodesk.Section');
      SectionTool.activate('x');
    };

    const restoreZoomSection = () => {
      const restoreView = JSON.parse(localStorage.getItem('storeView'));
      if (restoreView && restoreView.viewport.name !== 'ZoomSection') {
        const SectionTool = this.viewer.getExtension('Autodesk.Section');
        SectionTool.deactivate();
        inZoomSection = false;
        this.viewer.restoreState(restoreView);
      }
    };

    // Single Source
    let singleSource = new Autodesk.Viewing.UI.Button(
      'show-single-source-button'
    );
    singleSource.onClick = function (e) {
      if (!inSingleSource) {
        setViewExplode();
      } else {
        restoreViewExplode();
      }
    };
    singleSource.className = 'glyphicon glyphicon-euro';
    singleSource.setToolTip('Show Single Source');
    singleSource.setIcon('adsk-icon-camera');

    // Zoom Section
    let zoomSection = new Autodesk.Viewing.UI.Button(
      'show-zoom-section-button'
    );
    zoomSection.onClick = function (e) {
      if (!inZoomSection) {
        setZoomSection();
      } else {
        restoreZoomSection();
      }
    };
    zoomSection.className = 'glyphicon glyphicon-euro';
    zoomSection.setToolTip('Zoom to Section');
    zoomSection.setIcon('adsk-icon-structure');

    //Sub Toolbar
    this.subToolbar = new Autodesk.Viewing.UI.ControlGroup('my-custom-toolbar');
    this.subToolbar.addControl(singleSource);
    this.subToolbar.addControl(zoomSection);

    this.viewer.toolbar.addControl(this.subToolbar);
  };

  /**
   * @function initializeViewer
   * @param {string} urn
   * @description sets viewer to urn to display model
   */
  initializeViewer = async urn => {
    const { access_token: token } = await getPublicToken();

    const viewerOptions = {
      env: 'AutodeskProduction',
      accessToken: token,
      api: 'derivativeV2'
    };

    this.viewer = new Autodesk.Viewing.Private.GuiViewer3D(
      this.viewerDiv.current,
      viewerOptions
    );

    //explode extension
    Autodesk.Viewing.theExtensionManager.registerExternalExtension(
      'WWSingleSource.Extension',
      window.origin + '/explosion-extension.js'
    );

    Autodesk.Viewing.Initializer(viewerOptions, () => {
      console.log('Initializer');
      const errorCode = this.viewer.start();
      this.viewer.autocam.shotParams.destinationPercent = 3;
      this.viewer.autocam.shotParams.duration = 3;

      if (!errorCode) {
        Autodesk.Viewing.Document.load(`urn:${urn}`, doc => {
          console.log('Autodesk.Viewing.Document.load');
          var defaultModel = doc.getRoot().search({ role: '3d' })[15];

          this.viewer.loadDocumentNode(doc, defaultModel);

          this.viewer.loadExtension('Autodesk.Explode');
          this.viewer.loadedExtensions['Autodesk.Explode'].activeStatus = true;

          this.viewer.impl.sceneUpdated(true);

          this.viewer.addEventListener(
            Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
            x => {
              this.props.store.setShowButton();
            }
          );

          this.viewer.addEventListener(
            Autodesk.Viewing.TOOLBAR_CREATED_EVENT,
            this.onToolbarCreated
          );

          this.viewer.addEventListener(
            Autodesk.Viewing.EXPLODE_CHANGE_EVENT,
            () => {
              const point = new THREE.Vector3();
              this.viewer
                .loadExtension('WWSingleSource.Extension')
                .then(externalExtension => {
                  externalExtension.handleExplode(
                    point,
                    this.viewer,
                    Autodesk.Viewing.EXPLODE_CHANGE_EVENT,
                    this.viewer.model.getVisibleBounds(true).center()
                  );
                });
            }
          );
        });
      } else {
        console.error('Error starting Forge Viewer - code:', errorCode);
      }
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Measure bounds onResize={this.handleResize.bind(this)}>
        {({ measureRef }) => (
          <div ref={measureRef}>
            <div ref={this.viewerDiv} className={classes.viewerDiv}></div>
          </div>
        )}
      </Measure>
    );
  }
}
decorate(ForgeViewer, {
  viewer: observable
});

const ObservableForgeViewer = inject('store')(observer(ForgeViewer));

export default withStyles(style)(ObservableForgeViewer);
